import React, { Component } from 'react';
import styled, { css } from 'styled-components'
import headerImage from './assets/img/header-image.png';
import { ReactComponent as LogoImage } from './assets/img/mm_sports_logo.svg';

import FormSignup from './Components/FormSignup';
import StoreSelector from './Components/StoreSelector';

import ThankYou from './Components/ThankYou';
import ResponseNotice from './Components/ResponseNotice';

const ThemeLight = css`
  background-color: #fff;
  color: #000;

  h1,h2 {
    color: #000;
  }
  .cls-1 {
    fill: #000;
  }
`;
const ThemeDark = css`
  background-color: #000;
  color: #fff;
  
  h1,h2,h3 {
    color: #fff;
  }

  .cls-1 {
    fill: #fff;
  }
`;

const MainContainer = styled.div`
  ${props => (props.step === 'start' ? ThemeDark : ThemeLight)};
  
  text-align: center;
  margin: 0 auto;
  
  h1, h2 {
    text-transform: uppercase;
    margin: 0;
  }
  
  h1 {
    font-size: 34px;
    line-height: 38px;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 38px;
    line-height: 48px;
    font-weight: 400;
  }
  
  @media (min-width: 768px) { 
    h1 {
      font-size: 46px;
      line-height: 52px;
    }
  
    h2 {
      font-size: 54px;
      line-height: 62px;
    }
  }

  @media (min-height: 1025px ) {
    h1 {
      font-size: 60px;
      line-height: 68px;
    }
  }
`;
const Header = styled.div`
  line-height: 0;
  overflow: hidden;
  max-height: 195px;

  @media (min-height: 1025px ) {
    max-height: 240px;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: 100% 0;
  }
`;
const Content = styled.div`
  padding: 30px 20px 75px;
  color: #000;
  
  @media (min-width: 560px ) {
    padding: 40px 40px 75px;
  }

  @media (min-height: 1025px ) {
    padding: 50px 40px 75px;
  }
`;

const Footer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;

  svg {
    width: 200px;
  }
`;

const stores = [
  {"name":"Eskilstuna","storeId":22},
  {"name":"GBG - Fröunda Torg","storeId":21},
  {"name":"GBG - Kungsgatan","storeId":31},
  {"name":"Gävle","storeId":29},
  {"name":"Jönköping","storeId":27},
  {"name":"Kungsbacka","storeId":26},
  {"name":"Kungälv","storeId":25},
  {"name":"Malmö - Baltzargatan","storeId":24},
  {"name":"Oslo","storeId":50},
  {"name":"STHLM - Kungsholmen","storeId":30},
  {"name":"STHLM - Sveavägen","storeId":23},
  {"name":"Uppsala", "storeId":32},
  {"name":"Örebro","storeId":28},
  {"name":"Swefit Expo 2/11-19","storeId":60}
];

const sweFitContent = {
  "formsignup": {
    "title": {
      "sv": "TÄVLA NU!"
    },
    "text": {
      "sv": "Skriv in din mailadress och ditt telefonnummer. När du registrerat dig är du med och tävlar om en årsförbrukning av XLNT Sports Energidryck! (Vinsten motsvarar 1 burk/dag). Vinnaren kontaktas per mail eller telefon senast 30 november."
    },
    "terms": {
        "sv": "Din integritet är viktig för oss och vi hanterar alltid uppgifter om dig på ett pålitligt och lagligt sätt. Vill du veta mer kan du läsa vår integritetspolicy på mmsport.se"
    },
    form: {
      emailPlaceholder: {
        sv: "Din e-postadress",
        no: "Din e-postadresse"
      },
      phonePlaceholder: {
        sv: "Ditt mobilnummer",
        no: "Ditt mobilnummer"
      },
      register: {
        sv: "Registrera mig",
        no: "Registrer meg"
      },
      invalidEmail: {
        sv: "Ogiltig epostadress",
        no: "Ugyldig e-postadresse"
      },
      alreadyCustomer: {
        sv: "Du är redan registrerad hos oss",
        no: "Du er allerede registrert hos oss"
      },
      error: {
        sv: "Något gick fel, vänligen försök igen eller kontakta butikspersonal",
        no: "Noe gikk galt, prøv igjen eller kontakt butikkpersonalet"
      }
    }
  },
  "thankyou": {
    "title": {
      "sv": "Tack, du är nu med i tävlingen!"
    },
    "description": {
      "sv": "Vi har även lagt till dig i MM Sports medlemsregister"
    },
    "btn": {
      "sv": "Tillbaka"
    },
  },
  "responseNotice": {
    "title": {
      "sv": "Tack, du är nu med i tävlingen!"
    },
    "text": {
      "sv": ""
    },
    "btn": {
      "sv": "Tillbaka"
    },
  },
}

const pageContent = {
  "formsignup": {
    "title": {
      "sv": "BLI MEDLEM HOS OSS OCH FÅ 15% VÄLKOMSTRABATT!*",
      "no": "BLI MEDLEM HOS OSS OG FÅ 15% VELKOMSTRABATT!*"
    },
    "text": {
      "sv": "<p>Få 15% rabatt på ditt första köp när du registrerar dig som medlem. Gäller endast ordinarie priser.<br>Som medlem får du bland annat:</p><p>- Digitalt kvitto<br>- Exklusiva erbjudanden & rabattkoder<br>- Förtur till utvalda kampanjer<br>- Information om nyheter & produktlanseringar<br>- Födelsedagsgåva</p>",
      "no": "Som medlem av oss vil du alltid motta nyheter, eksklusive tilbud, kampanjer for forhåndsvisning etc. Abonner på vårt nyhetsbrev for å være sikker på at du ikke savner det siste i kosttilskudd, trening og helse!"
    },
    "terms": {
        "sv": "Din integritet är viktig för oss och vi hanterar alltid uppgifter om dig på ett pålitligt och lagligt sätt. Vill du veta mer kan du läsa vår integritetspolicy på mmsport.se",
        "no": "Din integritet er viktig for oss, og vi håndterer alltid informasjonen din på en pålitelig og juridisk måte. Hvis du vil vite mer, kan du lese vår personvernpolicy på mmsport.no"
    },
    form: {
      emailPlaceholder: {
        sv: "Din e-postadress",
        no: "Din e-postadresse"
      },
      phonePlaceholder: {
        sv: "Ditt mobilnummer",
        no: "Ditt mobilnummer"
      },
      register: {
        sv: "Registrera mig",
        no: "Registrer meg"
      },
      invalidEmail: {
        sv: "Ogiltig epostadress",
        no: "Ugyldig e-postadresse"
      },
      invalidPhone: {
        sv: "Ogiltigt telefonnummer. Ange som +46XXXXXXXXX eller lämna tomt",
        no: "Ogiltigt telefonnummer. Ange som +46XXXXXXXXX eller lämna tomt"
      },
      invalidSocialSecurityNumber: {
        sv: "Ogiltigt personnummer. Ange som ååååmmdd-nnnn eller lämna tomt",
        no: "Ogiltigt personnummer. Ange som ååååmmdd-nnnn eller lämna tomt"
      },
      alreadyCustomer: {
        sv: "Du är redan registrerad hos oss",
        no: "Du er allerede registrert hos oss"
      },
      error: {
        sv: "Något gick fel, vänligen försök igen eller kontakta butikspersonal",
        no: "Noe gikk galt, prøv igjen eller kontakt butikkpersonalet"
      }
    }
  },
  "thankyou": {
    "title": {
      "sv": "Tack, du är nu medlem hos oss!",
      "no": "Takk, du er nå medlem av oss!"
    },
    "btn": {
      "sv": "Tillbaka"
    },
  },
  "responseNotice": {
    "title": {
      "sv": "Vad kul, vi ser att du redan är medlem hos oss.",
      "no": "Hva gøy, vi ser at du allerede er medlem av oss."
    },
    "text": {
      "sv": "Detta erbjudande gäller endast nya medlemmar. Tack för att du är en lojal kund! Håll utkik i din inkorg för exklusiva erbjudanden och spännande produktlanseringar - före alla andra.",
      "no": "Detta erbjudande gäller endast nya medlemmar. Tack för att du är en lojal kund! Håll utkik i din inkorg för exklusiva erbjudanden och spännande produktlanseringar - före alla andra."
    },
    "btn": {
      "sv": "Tillbaka",
      "no": "Tilbake"
    },
  },
}

class App extends Component {
  constructor(props) {
    super(props);

    this.setStep = this.setStep.bind(this);

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const storeId = parseInt(params.get('store_id')) || null
    const lang = storeId === 50 ? "no" : "sv";

    if (storeId === 60 ) {
      pageContent.start = sweFitContent.start;
      pageContent.formsignup = sweFitContent.formsignup;
      pageContent.responseNotice = sweFitContent.responseNotice;
      pageContent.thankyou = sweFitContent.thankyou;
    }

    this.state = {
      step: 'formsignup',
      language: lang,
      storeId: storeId
    }
  }

  setStep(step){
    this.setState({
      step: step
    })
  }

  fullscreenMode() {
    const docElm = document.documentElement;

    if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
    } 
    else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen(); 
    }   
  }
  __(content) {
    return content[this.state.language];
  }
  render() {
    let activeStep;
    let {step,language, storeId} = this.state;

    const validStoreId = storeId ? stores.some(el => el.storeId === storeId) : false;
    if (!validStoreId) {
      step = 'storeSelector'
    }
    //this.fullscreenMode();

    switch(step) {
      case 'storeSelector':
        activeStep = <StoreSelector />
        break;
      case 'formsignup':
        activeStep = <FormSignup changeStep={this.setStep} storeId={this.state.storeId} language={language} content={pageContent.formsignup} __={(content) => this.__(content)} />
        break;
      case 'thankyou':
        activeStep = <ThankYou changeStep={this.setStep} storeId={this.state.storeId} language={language} content={pageContent.thankyou} __={(content) => this.__(content)} />
        break;
      case 'responseNotice':
        activeStep = <ResponseNotice changeStep={this.setStep} language={language} content={pageContent.responseNotice} __={(content) => this.__(content)} />
        break;
      default:
        activeStep = null;
    }

    return (
      <MainContainer step={this.state.step} className='App'>
        <Header>
          <img src={headerImage} alt="header" />
        </Header>
        <Content>
          {activeStep}
        </Content>
        <Footer>
          <LogoImage />
        </Footer>
      </MainContainer>
    );
  }
}

export default App;
